<template>
    <Main :show-title="false" :grid="true">
        <section class="progress naked">
            <h2>Budget</h2>
            <header class="card">
                <p class="columns">
                    <span>
                        <strong class="percent">
                            <Placeholder v-if="budget.isLoading" :width="100" :border-radius="10"
                                         style="vertical-align: top; height: 0.7em;"/>
                            <template v-else>{{ budget.percent }}%</template>
                        </strong>
                    </span>
                </p>
            </header>
        </section>
    </Main>
</template>

<script>
import Main from '@/components/Main.vue';
import { reactive, toRefs } from 'vue';
// import useUnsubscribe from '@/utils/useUnsubscribe';
import { db } from '@/utils/firebase';

export default {
    name: 'AdminDashboard',
    components: {
        Main,
    },
    setup() {
        const sections = reactive({
            budget: {
                isLoading: true,
                total: 0,
            },
        });

        // Budget
        (async () => {
            const projects = await db.collectionGroup('projects').get();
            console.log(projects);
            // const { docs, isLoading } = useUnsubscribe(db.collectionGroup('projects'));
            // sections.budget.total = computed(() => docs.value);
            // sections.budget.rest = computed(() => sections.project.budget - docs.value.reduce((acc, budget) => acc + budget.payment.paid, 0) * 1.1);
            // sections.budget.percent = computed(() => Math.round(((sections.project.budget - sections.budget.rest) / sections.project.budget) * 100));
            // sections.budget.isLoading = isLoading;
        })();

        return { ...toRefs(sections) };
    },
};
</script>

<style scoped lang="less">

</style>
